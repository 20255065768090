@use '@typography' as *;
@use '@queries' as *;

.selected_filters {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 10px;
  width: 100%;
}

.chip {
  height: 38px;
  gap: 8px;
  border: 2px solid var(--cst-chips-simple-outline-default);
  background-color: var(--cst-chips-simple-background-default);
  border-radius: var(--cornerradius8);
  align-items: center;
  display: flex;
  text-align: left;
  padding: 8px 14px;

  p {
    @include typography-s;
  }

  @include min-1440-break {
    p {
      @include typography-base;
    }
    height: 42px;
    gap: 10px;
    padding: 12px 16px;
  }
}

.reset_chip {
  background-color: var(--cst-chips-transport-background-default);
  border: 2px solid var(--cst-chips-transport-outline-default);

  p {
    color: var(--cst-chips-transport-text-default);
  }
}

.icon {
  color: var(--cst-chips-transport-text-default);
}
