@use '@queries' as *;

.content {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: 16px;

  @include min-1440-break {
    gap: 18px;
  }
}

.header {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
